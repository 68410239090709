import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/ButtonDefault'

// Components
import Agenda from './Agenda'

const BlockGrey = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.color.face.grey};
`

const AgendaBlock = ({ className }) => {
  const { agendablock } = useStaticQuery(graphql`
    {
      agendablock: wordpressWpComponenten(wordpress_id: { eq: 304 }) {
        title
        acf {
          agenda {
            icon {
              localFile {
                publicURL
              }
            }
            title
            button {
              title
              url
            }
          }
        }
      }
    }
  `)
  return (
    <BlockGrey className="py-5 h-100 d-flex flex-column align-items-center">
      <img
        src={agendablock.acf.agenda.icon.localFile.publicURL}
        alt=""
        className="d-block mx-auto mb-4"
      />
      <ParseContent content={agendablock.acf.agenda.title} className="mb-5" />
      <Agenda />
    </BlockGrey>
  )
}

export default AgendaBlock
