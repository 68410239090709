/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import ImageBackground from 'components/shared/ImageBackground'

const Hero = styled(ImageBackground)`

  @media (min-width: 992px) {
    min-height: 275px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }
`

const HeroImage = ({ fields }) => (
  <Hero src={fields.image} aboveFold />
)

export default HeroImage