/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'
import Content from 'components/shared/Content'

const StyledHeader = styled.nav`
  background-color: ${(props) => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 140px;
  z-index: 1;

  @media (max-width: 991px) {
    height: 100px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 92px;
    width: 197px;

    @media (max-width: 992px) {
      height: 58px;
      width: auto;
    }
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.light};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const LogoContent = styled(Content)`
  font-family: ${props => props.theme.font.family.logo};
  color: ${props => props.theme.color.text.light};
  font-weight: bold;
  max-width: 220px;

  @media (min-width: 992px){
    font-size: ${props => props.theme.font.size[27]};
    line-height: 26px;
  }

  @media (max-width: 991px){
    font-size: ${props => props.theme.font.size[22]};
    line-height: 24px;
  }
`

const Header = () => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressMenusMenusItems(term_id: { eq: 2 }) {
        name
        items {
          title
          url
          child_items {
            title
            url
            slug
          }
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <Container className="container position-relative">
        <Brand to="/">
          <LogoContent content="Christelijke Gereformeerde Kerk Werkendam" />
        </Brand>

        <MenuMobile fields={fields} />
        <MenuDesktop fields={fields} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: ${(props) => props.theme.font.size.l};
        border-bottom: 1px solid #f1f1f1;
        display: block;
        padding: 10px 0;
        text-transform: lowercase;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButtonCloseTitle = styled.div`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;
  margin-top: 3px;
`

const MenuMobile = ({ fields }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton role="button" onClick={() => setIsOpen(!isOpen)}>
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>Menu</NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose role="button" onClick={() => setIsOpen(!isOpen)}>
            <NavButtonCloseCross />
            <NavButtonCloseTitle>Sluiten</NavButtonCloseTitle>
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  height: 100%;
  flex: 1;
  align-items: flex-end;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 30px;
`

const MenuDesktop = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  & > a {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-style: italic;
    color: ${(props) => props.theme.color.text.light};
    text-transform: lowercase;

    &[aria-current] {
      font-weight: ${(props) => props.theme.font.weight.l};
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.color.text.light};
    }
  }

  &:hover {
    .sub-menu {
      visibility: visible;
      opacity: 1;
    }
  }
`

const SubItem = styled(Item)`
  margin-bottom: 10px;
`

const StyledUl = styled.ul`
  position: absolute;
  top: 135px;
  visibility: hidden;
  opacity: 0;
  margin-left: --1rem;
  padding: 20px 20px 10px;
  width: auto;
  min-width: 16rem;
  list-style: none;
  background: ${props => props.theme.color.face.secondary};
  z-index: 99;
  transition: .25s;
`

const Menu = ({ fields }) => (
  <>
    {fields.items.map((item, index) => (
      <Item
        key={index}
        className={`header-li${
          item.child_items !== null ? ' has-children' : ''
        }`}
      >
        <CustomLink
          to={item.url}
          className={`${item.child_items !== null ? 'has-children' : ''}`}
        >
          {item.title}
        </CustomLink>

        {item.child_items !== null && (
          <>
            <StyledUl className="sub-menu">
              {item.child_items.map((child_item, key) => (
                <SubItem key={key} className="sub-menu-item">
                  <CustomLink to={child_item.url}>
                    <span
                      dangerouslySetInnerHTML={{ __html: child_item.title }}
                    />
                  </CustomLink>
                </SubItem>
              ))}
            </StyledUl>
          </>
        )}
      </Item>
    ))}
  </>
)

export default Header
