/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.face.secondary};
  color: ${(props) => props.theme.color.text.light};
  border-bottom: 30px solid ${props => props.theme.color.face.main};

  & ul {
    list-style: none;
    margin-left: -2.3rem;

    & li {
      & a {
        &:hover {
          color: ${props => props.theme.color.face.main};
        }
      }
    }
  }
`

const Title = styled.h2`
  font-family: ${props => props.theme.font.family.secondary};
  font-style: italic;
  font-size: ${props => props.theme.font.size.l};
  color: ${props => props.theme.color.text.light};
`

const Content = styled(ParseContent)`
  font-style: italic;
`

const Footer = () => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 98 }) {
        acf {
          contactDetails: contact_details {
            title
            description
          }
          worship {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          associations {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          information {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          catechesis {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          library {
            title
            menu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container">
        <div className="row pt-5 pb-4">
          <div className="col-lg-4 col-md-6">
            <Title className="mb-3">{fields.acf.contactDetails.title}</Title>
            <Content content={fields.acf.contactDetails.description} />
          </div>
          <div className="col-lg-4 col-md-6">
            <Title className="mb-3">{fields.acf.worship.title}</Title>
            <ul>
              {fields.acf.worship.menu.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <Link to={url}>{title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-4 col-md-6">
            <Title className="mb-3">{fields.acf.associations.title}</Title>
            <ul>
              {fields.acf.associations.menu.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <Link to={url}>{title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row py-lg-5">
          <div className="col-lg-4 col-md-6">
            <Title className="mb-3">{fields.acf.information.title}</Title>
            <ul>
              {fields.acf.information.menu.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <Link to={url}>{title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-4 col-md-6">
            <Title className="mb-3">{fields.acf.catechesis.title}</Title>
            <ul>
              {fields.acf.catechesis.menu.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <Link to={url}>{title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-4 col-md-6">
            <Title className="mb-3">{fields.acf.library.title}</Title>
            <ul>
              {fields.acf.library.menu.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <Link to={url}>{title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
