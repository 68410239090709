import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

const AgendaItems = styled.div`
  position: relative;
`
const Item = styled(Link)`
  display: block;
  position: relative;
`

const Agenda = ({ className }) => {
  const { agenda } = useStaticQuery(graphql`
    {
      agenda: allWordpressWpAgenda(sort: {fields: date, order: ASC}, limit: 4) {
        edges {
          node {
            acf {
              item {
                time
                predecessor
              }
            }
            title
          }
        }
      }
    }
  `)

  return (
    <AgendaItems className="px-2 px-lg-5 mx-lg-3">
      {agenda.edges.map(({ node: { title, acf } }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Item to="/kerkdiensten/kerkdiensten-live" key={index} className="mb-4">
          <p className="font-weight-bold mb-0">{title}</p>
          {acf.item.map(({ time, predecessor }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={i} className="mb-0 pl-3">
              <span>
                {time}
                :
              </span>
              {' '}
              <span>{predecessor}</span>
            </p>
          ))}
        </Item>
      ))}
    </AgendaItems>
  )
}

export default Agenda
